var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12"},[_c('Card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('Accordion',{attrs:{"activeIndex":0}},[_c('AccordionTab',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"w-full flex flex-wrap justify-content-center md:justify-content-between align-items-center"},[_c('div',{staticClass:"informacoes text-center"},[_c('span',[_vm._v("Informações do Servidor - "+_vm._s(_vm.store.dados.matricula)+" - "+_vm._s(_vm.store.dados.servidor.nome))])]),_c('div',{staticClass:"tag"},[_c('Tag',{attrs:{"value":_vm.store.validacao.value,"severity":_vm.store.validacao.severity,"icon":_vm.store.validacao.icon}})],1)])]},proxy:true}])},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"col-12 md:col-6"},[_c('Card',{staticClass:"shadow-3 h-full",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Dados Pessoais ")]},proxy:true},{key:"content",fn:function(){return [_c('p',[_vm._v(" Nome Completo: "),_c('b',[_vm._v(_vm._s(_vm.store.dados.servidor.nome))])]),_c('p',[_vm._v(" CPF: "),_c('b',[_vm._v(_vm._s(_vm._f("mascaraCpf")(_vm.store.dados.servidor.cpf)))])]),_c('p',[_vm._v(" E-Mail: "),_c('b',[_vm._v(_vm._s(_vm.store.dados.servidor.email))])]),_c('p',[_vm._v(" Telefone: "),_c('b',[_vm._v(_vm._s(_vm.store.dados.servidor.celular))])])]},proxy:true}])})],1),_c('div',{staticClass:"col-12 md:col-6"},[_c('Card',{staticClass:"shadow-3 h-full",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Dados Profissionais ")]},proxy:true},{key:"content",fn:function(){return [_c('p',[_vm._v(" Matricula: "),_c('b',[_vm._v(_vm._s(_vm.store.dados.matricula))])]),_c('p',[_vm._v(" Entidade: "),_c('b',[_vm._v(_vm._s(_vm.store.dados.entidade.nome)+" ")])]),(_vm.store.dados.vinculo)?_c('p',[_vm._v(" Status: "),(
                          _vm.store.dados.vinculo.id == 2 ||
                          _vm.store.dados.vinculo.id == 3 ||
                          _vm.store.dados.vinculo.id == 7
                        )?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(_vm.store.dados.vinculo.nome)+" ")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.store.dados.vinculo.nome)+" ")]),(!_vm.store.dados.vinculo)?_c('span',[_vm._v(" TIPO DE VÍNCULO NÃO INFORMADO (VER CONTRACHEQUE) ")]):_vm._e()]):_vm._e(),(_vm.store.dados.dataAdmissao)?_c('p',[_vm._v(" Data de Admissão: "),_c('b',[_vm._v(_vm._s(_vm._f("formatarData")(_vm.store.dados.dataAdmissao)))])]):_vm._e(),(_vm.store.dados.dataAfastamento)?_c('p',[_vm._v(" Data de Afastamento:"),_c('b',[_vm._v(_vm._s(_vm._f("formatarData")(_vm.store.dados.dataAfastamento)))])]):_vm._e(),_c('p',[_vm._v(" Município de Lotação: "),_c('b',[_vm._v(_vm._s(_vm.store.dados.municipioLotacao))])]),(_vm.loadingSpinner)?_c('ProgressSpinner',{staticClass:"loading",staticStyle:{"width":"60px","height":"60px"},attrs:{"strokeWidth":"5"}}):_vm._e(),_c('div',{staticClass:"p-fluid formgrid grid"},[(_vm.store.dados.afastado)?_c('div',{staticClass:"field col-6 md:col-3"},[_c('span',{staticClass:"dados-matricula-label"},[_vm._v("Afastado:")]),_c('span',[_vm._v(_vm._s(_vm.store.dados.afastado == true ? 'Sim' : 'Não'))])]):_vm._e(),(_vm.store.dados.exonerado)?_c('div',{staticClass:"field col-6 md:col-3"},[_c('span',{staticClass:"dados-matricula-label"},[_vm._v("Exonerado:")]),_c('span',[_vm._v(_vm._s(_vm.store.dados.exonerado == true ? 'Sim' : 'Não'))])]):_vm._e(),(_vm.store.dados.servidor.falecido)?_c('div',{staticClass:"field col-6 md:col-3"},[_c('span',{staticClass:"dados-matricula-label"},[_vm._v("Falecido:")]),_c('span',[_vm._v(_vm._s(_vm.store.dados.servidor.falecido == true ? 'Sim' : 'Não'))])]):_vm._e()])]},proxy:true}])})],1)])])],1)]},proxy:true}])})],1),_c('div',{staticClass:"col-12"},[(_vm.store.validacao.servidorApto)?_c('div',{staticClass:"flex justify-content-center flex-wrap gap-2"},[(
          (_vm.$auth.hasRoleConsignatariaAdmin() || _vm.$auth.hasRoleAverbar()) &&
          !_vm.store.dados.bloqueada &&
          !_vm.suspensaoAtiva
        )?_c('Button',{staticClass:"p-button mr-2 mt-2 md:mt-0",attrs:{"icon":"pi pi-dollar","label":"Nova Averbação"},on:{"click":function($event){return _vm.consignacaoOnline()}}}):_vm._e(),(
          (_vm.$auth.hasRoleConsignatariaAdmin() ||
            _vm.$auth.hasRoleReservaCartao()) &&
          !_vm.store.dados.bloqueada &&
          !_vm.suspensaoAtiva
        )?_c('Button',{staticClass:"p-button-warning p-button mr-2 mt-2 md:mt-0",attrs:{"icon":"pi pi-credit-card","label":"Nova Reserva"},on:{"click":function($event){return _vm.novaReservaCartao()}}}):_vm._e(),(
          (_vm.$auth.hasRoleConsignatariaAdmin() ||
            _vm.$auth.hasRoleCompraDivida()) &&
          !_vm.store.dados.bloqueada &&
          !_vm.suspensaoAtiva
        )?_c('Button',{staticClass:"p-button-danger p-button mr-2 mt-2 md:mt-0",attrs:{"icon":"pi pi-shopping-cart","label":"Nova Portabilidade"},on:{"click":function($event){return _vm.iniciarCompra()}}}):_vm._e(),(
          (_vm.$auth.hasRoleConsignatariaAdmin() || _vm.$auth.hasRoleRenegociar()) &&
          !_vm.store.dados.bloqueada &&
          !_vm.suspensaoAtiva
        )?_c('Button',{staticClass:"p-button-success p-button mr-2 mt-2 md:mt-0",attrs:{"icon":"pi pi-sync","label":"Renegociação"},on:{"click":function($event){return _vm.renegociarOnline()}}}):_vm._e(),(_vm.suspensaoAtiva)?_c('span',{staticClass:"suspensao-error mt-2 p-text-danger"},[_c('i',{staticClass:"pi pi-exclamation-circle error-icon"}),_vm._v(" A consignatária selecionada está suspensa, somente operações de Cancelamento podem ser realizadas com esta entidade. Entre em contato com a Entidade para regularizar o seu contrato. ")]):_vm._e()],1):_vm._e()]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }